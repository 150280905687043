import React, { useState } from 'react';
import Modal from '../utils/Modal';
import {Link} from "gatsby"

import {SlideshowLightbox, Image} from "lightbox.js-react";

function FeatureWalkthroughNext() {

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <section>

      <section>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
          <div className="flex flex-wrap items-center mx-auto max-w-7xl">
            <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
              <div>
                <div className="relative w-full max-w-lg">
                  <div className="absolute top-0 rounded-full bg-violet-300 -left-4 w-72 h-72 mix-blend-multiply 
                  filter blur-xl opacity-70 animate-blob"></div>

                  <div className="absolute rounded-full bg-fuchsia-300 -bottom-12 right-20 w-72 h-72 mix-blend-multiply 
                  filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                  <div className="relative">
                    <img className="object-cover object-center mx-auto rounded-lg shadow-2xl" alt="hero" 
                    src="https://i.imgur.com/5oAfb4u.gif" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
              <span className="mb-8 text-xs font-bold tracking-widest text-blue-600 uppercase"> Feature </span>
              <h1 className="mb-8 text-4xl font-bold leading-none tracking-tighter text-gray-900 md:text-7xl lg:text-5xl">
                Built-in                 <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-purple-600"
                 data-aos="fade-in" data-aos-delay="90">animations</span>
              </h1>
              <p className="mb-8 text-base leading-relaxed text-left text-gray-500">
              Lightbox.js contains in-built animations for a seamless user experience.
                </p>
              <div className="mt-0 lg:mt-6 max-w-7xl sm:flex">
                <div className="mt-3 rounded-lg sm:mt-0">
                <Link to="/pricing">
                    <button className="items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out 
                    transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                    focus:ring-blue-500">Purchase license</button>
                  </Link>
                </div>
                <div className="mt-3 rounded-lg sm:mt-0 sm:ml-3">
                <Link to="/demo/">
                  <button className="items-center block px-10 py-3.5 text-base font-medium text-center text-blue-600 transition duration-500 
                  ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 
                  focus:ring-gray-500">See demos</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
          
          <div className="flex flex-wrap items-center mx-auto max-w-7xl">
          <div className="flex flex-col items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 lg:pl-6 xl:pl-4 md:mb-0 xl:mt-0">
              <span className="mb-8 text-xs font-bold tracking-widest text-blue-600 uppercase"> Feature </span>
              <h1 className="mb-8 text-4xl font-bold leading-none tracking-tighter text-gray-900 md:text-7xl lg:text-5xl">
              Intuitive                 <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-green-600"
                 data-aos="zoom-in" data-aos-delay="200">zooming</span>
              </h1>
              <p className="mb-8 text-base leading-relaxed text-left text-gray-500">
              No matter whether your users are on their mobile or desktop, images can be zoomed into using the mouse wheel, clicks, or pinch-to-zoom.
                </p>
              <div className="mt-0 lg:mt-6 max-w-7xl sm:flex">
                <div className="mt-3 rounded-lg sm:mt-0">
                <Link to="/docs/">
                    <button className="items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out 
                    transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                    focus:ring-blue-500">View Docs</button>
                  </Link>
                </div>
                <div className="mt-3 rounded-lg sm:mt-0 sm:ml-3">
                <Link to="/demo/">
                  <button className="items-center block px-10 py-3.5 text-base font-medium text-center text-blue-600 transition duration-500 
                  ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 
                  focus:ring-gray-500">See demos</button></Link>
                </div>
              </div>
            </div>
            <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl pl-8">
              <div>
                <div className="relative w-full max-w-lg">
                  <div className="absolute top-0 rounded-full bg-green-300 -left-4 w-72 h-72 mix-blend-multiply 
                  filter blur-xl opacity-70 animate-blob"></div>

                  <div className="absolute rounded-full bg-blue-300 -bottom-12 right-20 w-72 h-72 mix-blend-multiply 
                  filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                  <div className="relative">
                    <img className="object-cover object-center mx-auto rounded-lg shadow-2xl" alt="hero" 
                    src="https://i.imgur.com/n7HvgOX.gif" />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      
      <section>
        <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 md:px-12 lg:px-24 lg:py-24">
          <div className="flex flex-wrap items-center mx-auto max-w-7xl">
            <div className="w-full lg:max-w-lg lg:w-1/2 rounded-xl">
              <div>
                <div className="relative w-full max-w-lg">
                  <div className="absolute top-0 rounded-full bg-violet-300 -left-4 w-72 h-72 mix-blend-multiply 
                  filter blur-xl opacity-70 animate-blob"></div>

                  <div className="absolute rounded-full bg-violet-300 -bottom-12 right-20 w-72 h-72 mix-blend-multiply 
                  filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
                  <div className="relative">
                    <img className="object-cover object-center mx-auto rounded-lg shadow-2xl" alt="hero" 
                    src="https://i.imgur.com/ehgapw9.gif" />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start mt-12 mb-16 text-left lg:flex-grow lg:w-1/2 lg:pl-6 xl:pl-24 md:mb-0 xl:mt-0">
              <span className="mb-8 text-xs font-bold tracking-widest text-blue-600 uppercase"> Feature </span>
              <h1 className="mb-8 text-4xl font-bold leading-none tracking-tighter text-gray-900 md:text-7xl lg:text-5xl">
              Built-in  <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-purple-600"
                      data-aos="fade-in" data-aos-delay="90">Next.js support</span>
              </h1>
              <p className="mb-8 text-base leading-relaxed text-left text-gray-500">
              Get your lightbox up-and-running in minutes with our quick-start guide and built-in support for SSR frameworks, including Next.js and Gatsby.
                </p>
              <div className="mt-0 lg:mt-6 max-w-7xl sm:flex">
                <div className="mt-3 rounded-lg sm:mt-0">
                  <a href="https://github.com/silvia-odwyer/lightbox.js">
                    <button className="items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out 
                    transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                    focus:ring-blue-500">View on GitHub</button>
                  </a>

                </div>
                <div className="mt-3 rounded-lg sm:mt-0 sm:ml-3">
                  <Link to="/demo/">
                  <button className="items-center block px-10 py-3.5 text-base font-medium text-center text-blue-600 transition duration-500 
                  ease-in-out transform border-2 border-white shadow-md rounded-xl focus:outline-none focus:ring-2 focus:ring-offset-2 
                  focus:ring-gray-500">See demos</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
{/* 
<div className="relative overflow-hidden md:pt-8 md:grid md:grid-cols-2">


<div className="max-w-7xl mx-auto">
<div className="relative mt-4 z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

  <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
    <div className="sm:text-center lg:text-left">
      <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline dark:text-gray-300"  data-aos="zoom-y-out">Built-in</span>{' '}
                <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-purple-600"
                 data-aos="fade-in" data-aos-delay="90">animations</span>
              </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
       data-aos="fade-up" data-aos-delay="300">
Lightbox.js contains in-built animations for a seamless user experience.
      </p>
    </div>
  </div>
</div>
</div>


<img
      src={"https://i.imgur.com/5oAfb4u.gif"}
      alt={"neon"}
      className="w-full  object-center object-cover"
    />

</div> */}




    {/* <div className="relative overflow-hidden pt-8 grid grid-cols-2">
    <div className="mt-6 max-w-2xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
        <Image image={{"src":"https://source.unsplash.com/600x800/?colorful+fruit", "title": "Colorful fruit"}} />
        <Image image={{"src":"https://source.unsplash.com/600x800/?bold", "title": "Colorful fruit"}} />
        <Image image={{"src":"https://source.unsplash.com/600x800/?hot+air+balloon", "title": "Colorful fruit"}} />

        <img
              src={"https://source.unsplash.com/600x800/?pink+background"}
              alt={"neon"}
              className="w-full h-full object-center object-cover"
            />
              <img
                src={"https://source.unsplash.com/1200x1000/?hot+air+balloon"}
                className="w-full h-full object-center object-cover"
              />
              <img
                src={"https://source.unsplash.com/1000x700/?bold"}
                className="w-full h-full object-center object-cover"
              />


      </div>

      <div className="max-w-7xl mx-auto">
        <div className="relative mt-48 z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

          <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline dark:text-gray-300"  data-aos="zoom-in" >Intuitive</span>{' '}
                <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-green-600"
                 data-aos="zoom-in" data-aos-delay="200">zooming</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
               data-aos="fade-up" data-aos-delay="300">
              No matter whether your users are on their mobile or desktop, images can be zoomed into using the mouse wheel, clicks, or pinch-to-zoom.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div> */}

{/* 
    <div className="relative overflow-hidden pt-8 md:grid md:grid-cols-2">


        <img
              src={"https://i.imgur.com/n7HvgOX.gif"}
              alt={"neon"}
              className="w-full  object-center object-cover"
            />

      <div className="max-w-7xl mx-auto">
        <div className="relative mt-12 z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

          <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline dark:text-gray-300"  data-aos="zoom-in" >Intuitive</span>{' '}
                <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-blue-400 to-green-600"
                 data-aos="zoom-in" data-aos-delay="200">zooming</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
               data-aos="fade-up" data-aos-delay="300">
              No matter whether your users are on their mobile or desktop, images can be zoomed into using the mouse wheel, clicks, or pinch-to-zoom.
              </p>
            </div>
          </div>
        </div>
      </div>

    </div> */}

    {/* <div className="relative overflow-hidden pt-8 md:grid md:grid-cols-2">

      <div className="max-w-7xl mx-auto">
      <div className="relative mt-4 z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

        <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                      <span className="block xl:inline dark:text-gray-300"  data-aos="zoom-y-out">Customization &</span>{' '}
                      <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-green-400 to-purple-600"
                      data-aos="fade-in" data-aos-delay="90">theming</span>
                    </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0"
            data-aos="fade-up" data-aos-delay="300">
              You can customize the look and feel of the UI for a unique look that reflects your site's unique flair.
            </p>
          </div>
        </div>
      </div>
      </div>

      <img
            src={"https://i.imgur.com/ehgapw9.gif"}
            alt={"Lightbox.js with café dessert items"}
            className="w-full  object-center object-cover"
          />

      </div> */}


    {/* <div className="relative overflow-hidden pt-8 grid grid-cols-2">


      <div className="max-w-7xl mx-auto">
        <div className="relative mt-48 z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

          <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-800 sm:text-5xl md:text-6xl">
                <span className="block xl:inline text-gray-800 dark:text-gray-300"  data-aos="zoom-y-out">Customization and</span>{' '}
                <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-pink-800" 
                data-aos="zoom-y-out"
                 data-aos-delay="90">theming</span>
              </h1>
              <p data-aos="zoom-in" data-aos-delay="300" className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
              A fully-customizable React lightbox, with mobile-support, themes and much more.

              </p>

              <ul className="text-lg text-gray-400 -mb-2">
                    <li className="flex items-center mb-2">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Duis aute irure dolor in reprehenderit</span>
                    </li>
                    <li className="flex items-center mb-2">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Excepteur sint occaecat</span>
                    </li>
                    <li className="flex items-center">
                      <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                      </svg>
                      <span>Amet consectetur adipiscing elit</span>
                    </li>
                  </ul>

            </div>
          </div>
        </div>
      </div>

      <div className="">
        <img
          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://i.imgur.com/n7HvgOX.gif"
          alt=""
        />
      </div>

    </div> */}
    
    {/* <div className="relative overflow-hidden pt-8 grid grid-cols-2">
      <div className="">
        <img
          className="h-56 w-full sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://i.imgur.com/xMPMSza.png"
          alt=""
        />
      </div> */}

    {/* <div className="max-w-7xl mx-auto">

      <div className="relative mt-48 z-10 pb-8 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">

        <div className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">

          <div className="sm:text-center lg:text-left">
            <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline text-gray-800 dark:text-gray-300"  data-aos="zoom-y-out">Quick and easy</span>{' '}
              <span className="block xl:inline text-transparent bg-clip-text bg-gradient-to-br from-pink-400 to-pink-800" 
              data-aos="zoom-y-out"
              data-aos-delay="90">to set-up</span>
            </h1>
            <p data-aos="zoom-in" data-aos-delay="300" className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Get started in three quick steps and you'll have an animated lightbox setup for your website's imagery.

            </p>

            <ul className="text-lg text-gray-400 -mb-2">
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Duis aute irure dolor in reprehenderit</span>
                  </li>
                  <li className="flex items-center mb-2">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Excepteur sint occaecat</span>
                  </li>
                  <li className="flex items-center">
                    <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                    </svg>
                    <span>Amet consectetur adipiscing elit</span>
                  </li>
                </ul>

          </div>
        </div>
      </div>
    </div> */}


    {/* </div> */}


    </section>
  );
}

export default FeatureWalkthroughNext;