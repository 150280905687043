import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import FeatureListBlocksNext from "../components/FeatureListBlocksNext";
import BlogRoll from "../components/BlogRoll";
import Features from "../components/Features";
import Header from "../components/Header";
import MobileScreens from "../components/MobileScreens";

import HeroFeatures from "../components/HeroFeatures";
import Footer from "../components/Footer";
import HeroHomeGradientNext from "../components/HeroHomeGradientNext";
import HeroHome from "../components/HeroHome";
import HorizontalHeroHome from "../components/HorizontalHeroHome";
import Stats from "../components/Stats";
import FeaturesZigzag from "../components/FeaturesZigzag";
import FeaturesBlocks from "../components/FeaturesBlocks";
import Demo from "../components/Demo";
import PricingTables from "../components/PricingTables";
import FeatureWalkthroughNext from "../components/FeatureWalkthroughNext";
import Cta from "../components/Cta";
import CtaNext from "../components/CtaNext";

import News from "../components/News";

import FullWidthImage from "../components/FullWidthImage";
import DarkThemeDemo from "../components/DarkThemeDemo";

// eslint-disable-next-line
export const NextJsPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
            <main className="grow">
              <Header />
              {/*  Page illustration */}
              {/* <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                <PageIllustration />
              </div> */}

              {/*  Page sections */}
              <HeroHomeGradientNext />
              <FeatureListBlocksNext />
              <News />

              <FeatureWalkthroughNext />
              {/* <MobileScreens /> */}
              <Demo />

              {/* <HeroFeatures /> */}
              {/* <Stats /> */}
              {/* <FeaturesZigzag /> */}
              {/* <FeatureListBlocks /> */}
              <DarkThemeDemo />

              {/* <CaseStudies /> */}
              <PricingTables />
              <CtaNext /> 

              </main>

              <Footer overrideDarkTheme={true}/>
      {/* <FullWidthImage img={heroImage} title={title} subheading={subheading} /> */}
      {/* <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{mainpitch.description}</h3>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {heading}
                      </h3>
                      <h1 className="text-3xl font-bold underline">
                        Hello world!
                      </h1>
                      <p>{description}</p>
                    </div>
                  </div>
                  <Features gridItems={intro.blurbs} />
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/products">
                        See all products
                      </Link>
                    </div>
                  </div>
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Latest stories
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

NextJsPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const NextJsPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <NextJsPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

NextJsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default NextJsPage;

export const pageQuery = graphql`
  query NextJsPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "nextjs-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
